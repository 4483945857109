import React from 'react';
import { PageProps } from 'gatsby';


import { IndexStyle, GlobalStyle } from '../components/indexStyle';
import { Header } from '@/components/header/header';
import { Gallery } from '@/components/gallery/gallery';
import { Helmet } from "react-helmet"
import { ContactForm } from '@/components/contactForm/contactForm';

const Home: React.FC<PageProps> = () => (
    <IndexStyle>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Vandepitte — Hijswerken &amp; Transport</title>
            <meta name="description" content="Vandepitte BVBA, uw partner voor alles van transport en hijswerken!" />

        </Helmet>
        <Header />

        <main>

            <h2><strong>Privacy Policy</strong></h2>
            <p><strong>Privacy policy</strong> voor Vandepitte BVBA, eigenaar van vandepittebvba.be</p>

            <p><strong>1) Waarborgen Privacy</strong></p>
            <p>Het waarborgen van de privacy van bezoekers van vandepittebvba.be is een belangrijke taak voor ons. Daarom beschrijven we in onze privacy policy welke informatie we verzamelen en hoe we deze informatie gebruiken.</p>
            <p><strong>2) Toestemming</strong></p>
            <p>Door de informatie en de diensten op vandepittebvba.be te gebruiken, gaat u akkoord met onze privacy policy en de voorwaarden die wij hierin hebben opgenomen.</p>
            <p><strong>3) Vragen</strong></p>
            <p>Als u meer informatie wilt ontvangen, of vragen hebt over de privacy policy van Vandepitte BVBA en specifiek vandepittebvba.be, kun u ons benaderen via e-mail. Ons e-mailadres is info@vandepittebvba.be.</p>
            <p><strong>4) Monitoren gedrag bezoeker</strong></p>
            <p>vandepittebvba.be maakt gebruik van verschillende technieken om bij te houden wie de website bezoekt, hoe deze bezoeker zich op de website gedraagt en welke pagina’s worden bezocht. Dat is een gebruikelijke manier van werken voor websites omdat het informatie oplevert op die bijdraagt aan de kwaliteit van de gebruikerservaring. De informatie die we, via cookies, registreren, bestaat uit onder meer IP-adressen, het type browser en de bezochte pagina’s.</p>
            <p>Tevens monitoren we waar bezoekers de website voor het eerst bezoeken en vanaf welke pagina ze vertrekken. Deze informatie houden we anoniem bij en is niet gekoppeld aan andere persoonlijke informatie, <a href="https://datingsitegratis.be/parship/" target="_blank">hier</a>.</p>
            <p><strong>5) Gebruik van cookies</strong></p>
            <p>vandepittebvba.be plaatst cookies bij bezoekers. Dat doen we om informatie te verzamelen over de pagina’s die gebruikers op onze website bezoeken, om bij te houden hoe vaak bezoekers terug komen en om te zien welke pagina’s het goed doen op de website. Ook houden we bij welke informatie de browser deelt.</p>
            <p><strong>6) Cookies uitschakelen</strong></p>
            <p>U kunt er voor kiezen om cookies uit te schakelen. Dat doet u door gebruik te maken de mogelijkheden van uw browser. U vindt meer informatie over deze mogelijkheden op de website van de aanbieder van uw browser.</p>
            <p><strong>7) Cookies van derde partijen</strong></p>
            <p>Het is mogelijk dat derde partijen, zoals Google, op onze website adverteren of dat wij gebruik maken van een andere dienst. Daarvoor plaatsen deze derde partijen in sommige gevallen cookies. Deze cookies zijn niet door vandepittebvba.be te beïnvloeden.</p>
            <p><strong>10) DART-cookie van Google</strong></p>
            <p>Het gebruik van een DART-cookie door Google, maakt het mogelijk dat er advertenties op onze website worden getoond. Daarnaast wordt deze cookie gebruikt voor het tonen van advertenties op andere websites. U kunt er voor kiezen om geen gebruik meer te maken van de DART Cookie. Dat doet u door de volgende website te bezoeken: <a href="https://support.google.com/adsense/answer/2839090?hl=nl" target="_blank" rel="noopener noreferrer">DART</a></p>
            <p>Deze privacy policy is gegenereerd door <a href="https://privacypolicygenerator.be/" target="_blank">privacypolicygenerator.be</a>.</p>
        </main>


    </IndexStyle>
);

export default Home;
